import * as React from 'react';

import { MessageType, ServerErrorMessage } from '@bumble/proto/bma';
import { ErrorBoundary } from '@bumble/react-error-boundary';
import { GlobalProps } from 'lib/app/global-props';
import { GlobalPropsProvider } from 'lib/app/global-props-provider';
import gelatoLogger from 'lib/gelato-logger';
import { RpcAny } from 'lib/rpc';
import { SHARE_IMAGE_URL } from 'lib/seo/seo';
import useBodyOSClasses from 'lib/use-body-os-classes';
import { useLazyLoadCss } from 'lib/use-lazy-load-css';
import NextApp, { AppProps as BaseAppProps, AppContext } from 'next/app';
import Head from 'next/head';

import AppContainer from 'containers/common/app/app-container';

import '../styles/base.scss';

declare const window: Window & {
    appProps: GlobalProps;
};

export type AppProps = BaseAppProps & {
    globalProps: GlobalProps;
};

function App(props: AppProps) {
    useBodyOSClasses();
    useLazyLoadCss();

    React.useEffect(() => {
        const globalTrack = (event: ErrorEvent) => {
            gelatoLogger.trackError(event.error, {
                origin: 'onerror',
            });
        };

        const promiseTrack = (promiseRejectionEvent: PromiseRejectionEvent) => {
            gelatoLogger.trackError(promiseRejectionEvent.reason, {
                origin: 'unhandledrejection',
            });
        };

        global.window.addEventListener('error', globalTrack);
        global.window.addEventListener('unhandledrejection', promiseTrack);

        return () => {
            global.window.removeEventListener('error', globalTrack);
            global.window.removeEventListener('unhandledrejection', promiseTrack);
        };
    }, []);

    React.useEffect(() => {
        const handleSessionFailure = (serverError: ServerErrorMessage) => {
            gelatoLogger.trackMessage('Attempt to use failed session', {
                debugInfo: JSON.stringify(serverError),
            });
        };

        RpcAny.onResponse(MessageType.CLIENT_SESSION_FAILED, handleSessionFailure);

        return () => {
            RpcAny.offResponse(MessageType.CLIENT_SESSION_FAILED, handleSessionFailure);
        };
    }, []);

    // We need to use proper way to get globalProps, SSR passes globalProps to app props via enhanceApp,
    // CSR uses global variable with the same data, see _document.tsx
    const globalProps = typeof window === 'undefined' ? props.globalProps : window.appProps;

    const fallbackFontsCss = `
        @font-face {
            font-family: BumbleSansCondensed;
            font-display: swap;
            font-weight: 700;
        }
        
        @font-face {
            font-family: BumbleSans;
            font-display: swap;
            font-weight: 700;
        }
        
        @font-face {
            font-family: BumbleSans;
            font-display: swap;
            font-weight: 500;
        }
        
        @font-face {
            font-family: BumbleSans;
            font-display: swap;
            font-weight: 400;
        }
    
        @font-face {
            font-family: BumbleSansCondensedFallback;
            font-weight: 700;
            src: local(Impact);
            ascent-override: 272%;
            descent-override: 183.18%;
            line-gap-override: 30.07%;
            size-adjust: 82%;
            font-display: optional;
        }
        
        @font-face {
            font-family: BumbleSansFallback;
            font-weight: 700;
            src: local(Arial Bold);
            ascent-override: 110.50%;
            descent-override: 29.29%;
            line-gap-override: 19.40%;
            size-adjust: 99%;
            font-display: optional;
        }
        
        @font-face {
            font-family: BumbleSansFallback;
            font-weight: 500;
            src: local(Arial Medium);
            ascent-override: 110.50%;
            descent-override: 29.29%;
            line-gap-override: 19.40%;
            size-adjust: 99%;
            font-display: optional;
        }
    
        @font-face {
            font-family: BumbleSansFallback;
            font-weight: 400;
            src: local(Arial);
            ascent-override: 110.50%;
            descent-override: 29.29%;
            line-gap-override: 19.40%;
            size-adjust: 99%;
            font-display: optional;
        }
    `;

    return (
        <React.StrictMode>
            <ErrorBoundary
                logger={(error, errorDetails) => {
                    if (process.env.NODE_ENV === 'production') {
                        gelatoLogger.trackError(error, {
                            ...errorDetails,
                            origin: 'ErrorBoundary',
                            debugInfo: errorDetails?.debug_info,
                        });
                    }
                }}
                debug={false}
            >
                <Head>
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes"
                    />
                    <meta name="referrer" content="origin-when-cross-origin" />
                    <meta itemProp="image" content={SHARE_IMAGE_URL} />
                    <style>{fallbackFontsCss}</style>
                    {/* preloading 2 main fonts */}
                    <link
                        rel="preload"
                        as="font"
                        href="/bumble-brand-assets/fonts/BumbleSans/BumbleSans-BoldCondensed.woff2"
                    />
                    <link
                        rel="preload"
                        as="font"
                        href="/bumble-brand-assets/fonts/BumbleSans/BumbleSans-Medium.woff2"
                    />
                    <link
                        id="deferred-styles"
                        href="/bumble-brand-assets/fonts/BumbleSans/fonts-bumble.css"
                        as="style"
                    />
                </Head>
                <GlobalPropsProvider value={globalProps}>
                    <AppContainer {...props} />
                </GlobalPropsProvider>
            </ErrorBoundary>
        </React.StrictMode>
    );
}

// Adding a custom getInitialProps allows us to disable "Automatic Static Optimization", SSR is used for each request
// It requires by _document.tsx to obtain global props from http headers
App.getInitialProps = (props: AppContext) => {
    return NextApp.getInitialProps(props);
};

export default App;

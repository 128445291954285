/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Gå tillbaka",
      "Hämta från App Store",
      "Hämta från Google Play",
      "Karusell",
      "Bild {{number}}",
      "Nästa bild",
      "Föregående bild",
      "Bild",
      "Bild {{number}} av {{total}}",
      "Sälj eller dela inte mina personuppgifter",
      "Sälj eller dela inte mina personliga uppgifter",
      "Bumbles startsida",
      "Stäng meny",
      "Öppna meny",
      "Val av språk",
      "Sidfot",
      "Tillgänglighet på Bumble",
      "Apple och logotypen för Apple är varumärken som tillhör Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Lediga jobb",
      "Events",
      "Google Play och logotypen för Google Play är varumärken som tillhör Google LLC.",
      "Riktlinjer",
      "Investerare",
      "Hantera cookies",
      "Hantera cookies",
      "Modern Slavery Act Statement",
      "Meddelande om insamling",
      "Sekretesspolicy",
      "Dejtidéer för varje årstid",
      "Villkor och regler",
      "Appen",
      "Sekretesspolicy för Consumer Health Data i delstaten Washington",
      "Om",
      "Ambassadörer",
      "The Buzz",
      "Shop",
      "Huvudmeny",
      "Gå till huvudinnehåll",
      "Logotypen för Bumble",
      "På Bumble är det sexigt att vara snäll. Här duger man som man är, och kvinnorna tar första steget. Alltid.",
      "Våga ta steget",
      "Sunda relationer är centralt för att leva ett positivt och produktivt liv. Bumble är designat för att du ska känna dig trygg när du skapar nya kontakter, oavsett om du vill dejta, lära känna nya vänner eller utvidga ditt jobbnätverk.",
      "Så gör Bumble skillnad",
      "Vi uppmuntrar till integritet, vänlighet, jämlikhet, trygghet och respekt i alla relationsstadier. Vi både välkomnar och hyllar alla orienteringar – oavsett om du är här för kärlek eller bara för att ha kul.",
      "Bumbles värderingar",
      "På Bumble är det kvinnorna som sätter tempot genom att ta första steget eller ställa in samtalspunkter för matchningar att svara på. På så sätt bryter vi de förlegade könsnormerna i dejtingvärlden och uppmanar till jämlikhet redan från början.",
      "Så fungerar Bumble",
      "Om oss",
      "Ladda ned nu",
      "Det är bättre på appen.",
      "Funktioner som gör nätdejting rolig på riktigt",
      "12 februari 2024",
      "Så får du mer känslomässig intimitet i ditt förhållande",
      "25 januari 2024",
      "Så använder du Bumble om du aldrig appdejtat innan",
      "5 februari 2024",
      "Deception Detector™",
      "Senaste nytt från dejtingvärlden",
      "Kolla in <b>För dig</b> och hitta profiler som valts ut baserat på dina inställningar och tidigare matchningar.",
      "Utvalt för dig",
      "Pausa dejtandet med <b>Snooze-läget</b>. Vi finns här när du är redo att komma igång igen.",
      "Ta en paus",
      "Med <b>video-</b> och <b>röstsamtal</b> kommer du så nära IRL som möjligt utan att lämna hemmet.",
      "Säg som det är",
      "Se mer",
      "Låt din matchning starta chatten med <b>Opening Moves</b>.",
      "Gör mindre, dejta mer",
      "Dejta och matcha var som helst i världen med <b>Travel-läget</b>.",
      "Ta Bumble med dig",
      "Fastnat för någon? Visa hur du känner med en <b>SuperSwipe</b>.",
      "Fånga deras uppmärksamhet",
      "Stick ut från mängden med <b>Compliments</b>.",
      "Få dem att rodna",
      "Dölj din profil från ditt ex, släkten eller chefen med <b>Inkognito-läget</b>.",
      "Gå undercover",
      "Dejta på ditt sätt",
      "Ett par månader senare träffade hon en av sina brudtärnor här också",
      "Hannah träffade sin man på Bumble.",
      "Carène och You-Liang träffades på Bumble, där You-Liang drogs till Carènes resebilder. Nu har de byggt hus ihop.",
      "Carène och You-Liang",
      "Brian och Kaci var stationerade utomlands tillsammans – men de träffades på Bumble",
      "Brian och Kaci",
      "Om det funkade för dem kan det funka för dig",
      "Ta nästa steg",
      "Se nyheterna",
      "Du förtjänar det bästa. Därför har vi tagit fram riktigt bra sätt att dejta mer och stressa mindre.",
      "Sätt tillbaka ribban där den hör hemma.",
      "Om Bumble",
      "Vi är den enda appen som gör dejting bättre genom att sätta kvinnornas upplevelse först. För när saker och ting är bättre för kvinnor är de bättre för alla.",
      "Make the first move™",
      "Hitta någon du vill dejta på riktigt och ta första steget. Knyt äkta kontakter, bonda över sådant ni båda älskar och – viktigast av allt – ha kul!",
      "Träffa någon med Bumble Date",
      "Hitta vänner med jobbförmåner. Träffa andra yrkespersoner och skaffa ett nytt jobb, en mentor eller en helt ny karriär.",
      "Ge karriären en knuff med Bumble Bizz",
      "Träffa någon som du. Eller inte som du. Skaffa nya vänner och hitta saker att göra ihop – hemma eller borta.",
      "Hitta nya vänner på Bumble For Friends",
      "Vi är inte bara till för dejting",
      "Bumble är dejtingappen där kvinnorna tar första steget. Det är appen som förändrade hur människor dejtar, knyter meningsfulla kontakter och nätverkar. Hämta nu.",
      "Bumble på {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Sorry, vi hittar tyvärr inte sidan du letar efter.",
      "Kontakta oss",
      "Logga in",
      "Hoppsan, något gick fel...",
      "(opens in new window)",
      "Vanliga frågor",
      "Cookie Policy",
      "Bumble – The Blog",
      "Lediga jobb",
      "Chatt",
      "Städer",
      "Hantera cookies",
      "Copyright © 2006 – dagens datum. Bumble. Alla rättigheter förbehålls.",
      "Rapport om dejting och trygghet",
      "Apple och logotypen för Apple är varumärken som tillhör Apple Inc.{{__EOL__}}Google Play och logotypen för Google Play är ett varumärke som tillhör Google LLC.",
      "Ladda ner",
      "Facebook",
      "Följ oss",
      "Hjälpcenter",
      "Instagram",
      "Språk",
      "LinkedIn",
      "Bumble är del av Bumble Inc.",
      "Sekretesspolicy",
      "Säkerhetscenter",
      "Webbplatskarta",
      "Uttalande om Modern Slavery Act",
      "Villkor och regler",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dagar",
                  "dagars"
            ],
            "singular": [
                  "dag",
                  "dags"
            ]
      },
      "hour": {
            "plural": [
                  "timmar",
                  "timmars"
            ],
            "singular": [
                  "timme",
                  "timmes"
            ]
      },
      "minute": {
            "plural": [
                  "minuter",
                  "minuters"
            ],
            "singular": [
                  "minut",
                  "minuts"
            ]
      },
      "month": {
            "plural": [
                  "månader",
                  "månaders"
            ],
            "singular": [
                  "månad",
                  "månads"
            ]
      },
      "second": {
            "plural": [
                  "sekunder",
                  "sekunders"
            ],
            "singular": [
                  "sekund",
                  "sekunds"
            ]
      },
      "year": {
            "plural": [
                  "år",
                  "års"
            ],
            "singular": [
                  "år",
                  "års"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
